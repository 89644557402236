import React,{useEffect} from "react"
import { useForm, ValidationError } from '@formspree/react';
import {motion } from "framer-motion";

const ContactMe = () => {
  useEffect(() => {
    document.title = 'Awais Nisar | Contact';
  }, []);

  const [state, handleSubmit] = useForm("xnqejqrr");
  if (state.succeeded) {
      return (
      <div className="mini-container">
      <p className="mess">Thanks for contacting me! I will respond back in within 24 hours.</p>
       <a href="/contact" className="go-back">Go Back</a>
       <footer className="mini">
          <p> Made by Awais Nisar 👨🏻‍💻</p>
        </footer>
      </div>
     );
  }
return(
    <>
        <motion.div
            initial = {{opacity: 0}} 
            animate={{opacity:1}} 
            exit={{opacity:0}}
            >
        <div className="contactintro">
          <div className="contactleft">
            <h1>Contact Me</h1>
            <p className="exper-desc">If you have any questions or just want to chat, you can reach me on almost any social media platform. I usually respond within 24 hours, so don't hesitate to send me a message. I look forward to connecting with you!</p>
            <div className="sm">
                  <ul>
                    <li><a href="https://github.com/nisarawais"><i className = "github" class="fa-brands fa fa-github"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/awaisahmednisar/"><i className="linkedin" class="fa-brands fa-linkedin-in"></i></a></li>
                    <li><a href="mailto:aanisar369@gmail.com"><i className="twitter" class="fas fa-envelope"></i></a></li>
                    </ul>
                </div>         
          </div>
          <div className="contactright">
          <>
          <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1625.05 814.99"
    className="test"
  >
    <title>mail</title>
    <ellipse
      cx={812.53}
      cy={711.14}
      rx={812.53}
      ry={103.85}
      style={{
        fill: "#d2d2d1",
        strokeWidth: 0,
      }}
    />
    <rect
      width={1269.67}
      height={697.4}
      x={177.69}
      rx={54.32}
      ry={54.32}
      style={{
        fill: "#314d69",
        strokeWidth: 0,
      }}
    />
    <path
      d="M177.69 54.34c0-30 24.32-54.32 54.32-54.32L1393.04 0c30 0 54.32 24.32 54.32 54.32-192.73 118.18-385.47 236.36-578.2 354.54-29.15 17.87-65.8 18.11-95.17.61C575.22 291.1 376.45 172.72 177.69 54.34Z"
      style={{
        fill: "#3d5a80",
        strokeWidth: 0,
      }}
    />
  </svg>
</>
          </div>
        </div>


        <div className="contactform">
        <form onSubmit={handleSubmit}>
              <h3>Shoot Me a Message</h3>
              <input type = "text" id="name" name="Name" placeholder="Name" htmlFor="name" required></input>
              <input type = "email" id="email" name="Email" placeholder="Email" label htmlFor="email" required></input>
              <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
              <input type = "text" id="phone" name="Phone Number" placeholder="Phone Number" htmlFor="phone"></input>
              <textarea id="message" name="Message" rows ="4" placeholder="Type your message in here" htmlFor="message" required></textarea>
              <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
              <button type = "submit" disabled={state.submitting} value = "Send">Send</button>
            </form>
          </div>
        <footer>
          <p> Made by Awais Nisar 👨🏻‍💻</p>
        </footer>
      </motion.div>
    </>
    )
}

export default ContactMe;
