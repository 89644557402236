import './App.css';
import { BrowserRouter } from "react-router-dom";
import NavBar from "./layout/NavBar";
import Router from "./layout/Router";
import useGoogleAnalytics from './hooks/useGoogleAnalytics';


function App() {
  
  //google tracking how many views :)
  useGoogleAnalytics('G-5T5RML1V3Q');

  return (
    <BrowserRouter>
    <NavBar />
    <Router />
  </BrowserRouter>
  );
}

export default App;
